<template>
  <div>
    <div v-if="!addVisible && !detailVisible">
      <div class="card" :style="{ height: height }">
        <div class="flexBox">
          <div class="btns" style="flex-shrink: 0">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="buildVisible = true"
              >创建交接班</el-button
            >
            <el-button type="primary" plain @click="addVisible = true"
              >交接班配置</el-button
            >
          </div>
          <div class="form">
            <el-form
              :inline="true"
              :model="form"
              class="demo-form-inline"
              label-width="90px"
            >
              <el-form-item label="交班人">
                <el-select v-model="form.relief_user_id">
                  <template v-for="item in employeeList">
                    <el-option
                      :label="item.realname"
                      :value="item.store_user_id"
                      :key="item.store_user_id"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="接班人">
                <el-select v-model="form.receive_user_id">
                  <template v-for="item in employeeList">
                    <el-option
                      :label="item.realname"
                      :value="item.store_user_id"
                      :key="item.store_user_id"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="开始时间">
                <el-date-picker
                  v-model="form.create_time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间">
                <el-date-picker
                  v-model="form.end_time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div style="margin: 0 10px 0 auto">
            <el-button
              @click="handleHeight"
              style="
                width: 40px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                padding: 0;
              "
            >
              <i class="el-icon-caret-bottom" v-if="height === '51px'"></i>
              <i class="el-icon-caret-top" v-else></i>
            </el-button>
          </div>
          <div style="display: flex">
            <el-button type="primary" @click="onSubmit" style="height: 40px"
              >搜索</el-button
            >
            <el-button @click="resetForm" style="height: 40px">重置</el-button>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table
          :data="tableData.list"
          style="width: 100%"
          v-loading="loading"
          height="700"
          @row-dblclick="handleDbclick"
          stripe
        >
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              :prop="item.field_alias"
              :label="item.field_text"
              align="center"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias !== 'status'">
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
                <template v-else>
                  <span v-if="scope.row.status === 10">交接中</span>
                  <span v-if="scope.row.status === 20">交接完成</span>
                  <span v-if="scope.row.status === 30">拒绝交接</span>
                  <span v-if="scope.row.status === 40">已撤销</span>
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            width="180"
            label="操作"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleSelect($event, scope.row)"
                >
                  <el-option
                    label="接班"
                    v-if="scope.row.receive_user_id == userInfo.account && scope.row.status === 10"
                    :value="20"
                  >
                  </el-option>
                  <el-option
                    label="拒接"
                    v-if="scope.row.receive_user_id == userInfo.account && scope.row.status === 10"
                    :value="30"
                  >
                  </el-option>
                  <el-option
                    label="取消"
                    v-if="scope.row.relief_user_id == userInfo.account && scope.row.status === 10"
                    :value="40"
                  >
                  </el-option>
                  <!-- <el-option label="打印" :value="50"> </el-option> -->
                  <el-option label="查看" :value="60"> </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="$store.state.pageSizes"
          :page-size="$store.state.pageSizes[0]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
        >
        </el-pagination>
      </div>
    </div>
    <Add v-if="addVisible" @onBack="handleBack" />
    <Detail v-if="detailVisible" :shifts_id="shifts_id" @onBack="detailVisible = false;" />
    <el-dialog
      title="创建交接班"
      :visible.sync="buildVisible"
      :before-close="handleCancel"
      width="30%"
    >
      <el-form :model="buildForm" label-width="100px">
        <el-form-item label="交班人">
          <span>{{ userInfo.realname }}</span>
        </el-form-item>
        <el-form-item label="接班人" required>
          <el-select v-model="buildForm.receive_user_id">
            <template v-for="item in employeeList">
              <el-option
                :label="item.realname"
                :value="item.store_user_id"
                :key="item.store_user_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="交班现金">
          <el-input
            type="number"
            style="width: 222px"
            v-model="buildForm.money"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="4"
            v-model="buildForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span style="color: #f56c6c">*最多统计30天销售数据</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleBuild" :loading="createLoading">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="replyVisible"
      width="25%"
      :before-close="handleReplyClose">
      <span v-if="status === 20">确认接班？</span>
      <el-input type="textarea" v-model="remark" placeholder="请填写拒接原因" :rows="4" v-if="status === 30"></el-input>
      <span v-if="status === 40">确认取消该接班？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleReplyClose">取 消</el-button>
        <el-button type="primary" @click="handleReply" :loading="replyLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import storage from "good-storage";
import {
  getEmployeeReq,
  getListReq,
  createReq,
  replyReq,
} from "@/api/work/changeShifts.js";
import Add from "./Add";
import Detail from './Detail';

export default {
  data() {
    const userInfo = storage.get("userInfo");
    return {
      userInfo,
      detailVisible: false,
      createLoading: false,
      replyLoading: false,
      title: '',
      remark: '',
      status: '',
      replyVisible: false,
      currRow: {},
      buildForm: {}, //  创建交接班
      buildVisible: false,
      addVisible: false,
      employeeList: [],
      loading: false,
      offsetHeight: "124px",
      height: "51px",
      form: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "交班人", field_alias: "relief_user_name" },
        { field_text: "接班人", field_alias: "receive_user_name" },
        { field_text: "状态", field_alias: "status" },
        { field_text: "实收合计(元)", field_alias: "paid_in_amount" },
        { field_text: "总交接数量", field_alias: "num" },
        { field_text: "开始时间", field_alias: "start_time" },
        { field_text: "结束时间", field_alias: "end_time" },
      ],
      tableData: {
        list: [{}],
        total: 0,
      },
    };
  },
  components: {
    Add,
    Detail,
  },
  created() {
    this.getEmployee();
    this.getList();
  },
  methods: {
    handleDbclick(row) {
      this.shifts_id = row.shifts_id;
      this.detailVisible = true;
    },
    handleReply() {
      this.replyLoading = true;
      replyReq({
        status: this.status,
        shifts_id: this.currRow.shifts_id,
        remark: this.remark,
      })
        .then((res) => {
          if (res.code === 1) {
            this.replyLoading = false;
            this.handleReplyClose();
            this.$message.success('操作成功');
            this.getList();
          }
        })
        .catch(() => {
          this.replyLoading = false;
        });
    },
    handleReplyClose() {
      this.replyVisible = false;
      this.currRow.select = '';
      console.log(this.currRow);
    },
    handleCancel() {
      this.buildForm = {};
      this.buildVisible = false;
    },
    handleBuild() {
      this.createLoading = true;
      createReq({
        ...this.buildForm,
      }).then((res) => {
        if (res.code === 1) {
          this.createLoading = false;
          this.buildVisible = false;
          this.buildForm = {};
          this.getList();
        }
      })
        .catch(() => {
          this.createLoading = false;
        });
    },
    handleBack(getList) {
      if (getList) {
        this.getList();
      }
      this.addVisible = false;
    },
    getList() {
      this.loading = true;
      getListReq({
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list = res.data.list.map((item, index) => {
              item.index = index + 1;
              return item;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getEmployee() {
      getEmployeeReq().then((res) => {
        if (res.code === 1) {
          this.employeeList = res.data;
        }
      });
    },
    handleSelect(e, row) {
      this.currRow = row;
      this.status = e;
      if (e === 20) {
        this.title = '接班';
        this.replyVisible = true;
      }
      if (e === 30) {
        this.title = '拒接';
        this.replyVisible = true;
      }
      if (e === 40) {
        this.title = '取消';
        this.replyVisible = true;
      }
      if (e === 60) {
        this.shifts_id = row.shifts_id;
        this.detailVisible = true;
        row.select = '';
      }
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    resetForm() {
      this.form = {};
      this.page = 1;
      this.getList();
    },
    handleHeight() {
      this.height = this.height === "51px" ? this.offsetHeight : "51px";
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 分页条数改变
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
.flexBox {
  display: flex;
}
.card {
  padding-bottom: 0;
  transition: all 0.3s;
  overflow: hidden;
}
/deep/ .el-button--text {
  &:nth-child(2) {
    color: #fd563a;
  }
}
.btns {
  margin-bottom: 20px;
  padding-right: 30px;
  position: relative;
  &::after {
    content: "";
    width: 1px;
    height: 38px;
    background: #e9e9e9;
    position: absolute;
    right: -18px;
    top: 3px;
  }
}
.form {
  padding-left: 30px;
}
</style>
