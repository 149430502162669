<template>
  <div v-if="!descLoading">
    <div class="card">
      <el-page-header @back="goBack" content="交接班详情"> </el-page-header>
    </div>
    <div class="wrapper" style="margin: 10px 0;">
      <div class="item" v-if="count_info.shifts_basic.length">
        <div class="title" style="margin-top: 0px">交接信息</div>
        <div class="info">
          <div
            class="block"
            v-for="item in count_info.shifts_basic"
            :key="item.title"
          >
            <div style="color: #909399; width: 120px">{{ item.title }}：</div>
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="item" v-if="count_info.stock_count.length">
        <div class="title">库存统计</div>
        <div class="info">
          <div
            class="block"
            v-for="item in count_info.stock_count"
            :key="item.title"
          >
            <div style="color: #909399; width: 120px">{{ item.title }}：</div>
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="item" v-if="count_info.trade_count.length">
        <div class="title">交易统计</div>
        <div class="info">
          <div
            class="block"
            v-for="item in count_info.trade_count"
            :key="item.title"
          >
            <div style="color: #909399; width: 120px">{{ item.title }}：</div>
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="padding: 20px;">
      <el-tabs v-model="activeName">
        <el-tab-pane label="销售数据" name="sale_info"></el-tab-pane>
        <el-tab-pane label="仓库数据" name="stock_info"></el-tab-pane>
        <!-- <el-tabs v-model="activeName2">
          <el-tab-pane label="材质成色" name="material"></el-tab-pane>
          <el-tab-pane label="仓库" name="stock"></el-tab-pane>
          <el-tab-pane label="品类" name="type"></el-tab-pane>
        </el-tabs> -->
        <el-radio-group v-model="activeName2">
          <el-radio-button
            label="material"
            >材质成色</el-radio-button
          >
          <el-radio-button
            label="stock"
            >仓库</el-radio-button
          >
          <el-radio-button
            label="type"
            >品类</el-radio-button
          >
        </el-radio-group>
        <template v-if="activeName === 'sale_info'">
          <div
            class="table-wrapper"
            v-if="
              sale_info[activeName2].by_grams.length ||
              sale_info[activeName2].by_unit.length ||
              sale_info[activeName2].count.length
            "
          >
            <div class="title">货品</div>
            <div style="display: flex; overflow-x: auto">
              <div class="box" v-if="sale_info[activeName2].by_grams.length">
                <div class="tag" v-if="sale_info[activeName2]">
                  销售合计 -
                  <span v-if="activeName2 === 'material'">材质成色</span>
                  <span v-if="activeName2 === 'stock'">仓库</span>
                  <span v-if="activeName2 === 'type'">品类</span>
                </div>
                <div class="table">
                  <div
                    class="row"
                    :class="itemIndex === 0 ? 'bg' : ''"
                    v-for="(item, itemIndex) in sale_info[activeName2].by_grams"
                    :key="itemIndex"
                  >
                    <div class="cell" v-for="(_, index) in item" :key="index">
                      {{ _ }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="box" v-if="sale_info[activeName2].by_unit.length">
                <div class="tag">
                  按克销售合计 -
                  <span v-if="activeName2 === 'material'">材质成色</span>
                  <span v-if="activeName2 === 'stock'">仓库</span>
                  <span v-if="activeName2 === 'type'">品类</span>
                </div>
                <div class="table">
                  <div
                    class="row"
                    :class="itemIndex === 0 ? 'bg' : ''"
                    v-for="(item, itemIndex) in sale_info[activeName2].by_unit"
                    :key="itemIndex"
                  >
                    <div class="cell" v-for="(_, index) in item" :key="index">
                      {{ _ }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="box" v-if="sale_info[activeName2].count.length">
                <div class="tag">
                  按件销售合计 -
                  <span v-if="activeName2 === 'material'">材质成色</span>
                  <span v-if="activeName2 === 'stock'">仓库</span>
                  <span v-if="activeName2 === 'type'">品类</span>
                </div>
                <div class="table">
                  <div
                    class="row"
                    :class="itemIndex === 0 ? 'bg' : ''"
                    v-for="(item, itemIndex) in sale_info[activeName2].count"
                    :key="itemIndex"
                  >
                    <div class="cell" v-for="(_, index) in item" :key="index">
                      {{ _ }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-wrapper" v-if="sale_info[activeName2].old.length">
            <div class="title">旧料</div>
            <div class="box" v-if="sale_info[activeName2].old.length">
              <div class="table">
                <div
                  class="row"
                  :class="itemIndex === 0 ? 'bg' : ''"
                  v-for="(item, itemIndex) in sale_info[activeName2].old"
                  :key="itemIndex"
                >
                  <div class="cell" v-for="(_, index) in item" :key="index">
                    {{ _ }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="activeName === 'stock_info'">
          <div
            class="table-wrapper"
            v-if="
              stock_info[activeName2].by_grams.length ||
              stock_info[activeName2].by_unit.length ||
              stock_info[activeName2].count.length
            "
          >
            <div class="title">货品</div>
            <div style="display: flex; overflow-x: auto">
              <div class="box" v-if="stock_info[activeName2].by_grams.length">
                <div class="tag" v-if="stock_info[activeName2]">
                  库存合计 -
                  <span v-if="activeName2 === 'material'">材质成色</span>
                  <span v-if="activeName2 === 'stock'">仓库</span>
                  <span v-if="activeName2 === 'type'">品类</span>
                </div>
                <div class="table">
                  <div
                    class="row"
                    :class="itemIndex === 0 ? 'bg' : ''"
                    v-for="(item, itemIndex) in stock_info[activeName2].by_grams"
                    :key="itemIndex"
                  >
                    <div class="cell" v-for="(_, index) in item" :key="index">
                      {{ _ }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="box" v-if="stock_info[activeName2].by_unit.length">
                <div class="tag">
                  库存按克合计 -
                  <span v-if="activeName2 === 'material'">材质成色</span>
                  <span v-if="activeName2 === 'stock'">仓库</span>
                  <span v-if="activeName2 === 'type'">品类</span>
                </div>
                <div class="table">
                  <div
                    class="row"
                    :class="itemIndex === 0 ? 'bg' : ''"
                    v-for="(item, itemIndex) in stock_info[activeName2].by_unit"
                    :key="itemIndex"
                  >
                    <div class="cell" v-for="(_, index) in item" :key="index">
                      {{ _ }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="box" v-if="stock_info[activeName2].count.length">
                <div class="tag">
                  库存按件合计 -
                  <span v-if="activeName2 === 'material'">材质成色</span>
                  <span v-if="activeName2 === 'stock'">仓库</span>
                  <span v-if="activeName2 === 'type'">品类</span>
                </div>
                <div class="table">
                  <div
                    class="row"
                    :class="itemIndex === 0 ? 'bg' : ''"
                    v-for="(item, itemIndex) in stock_info[activeName2].count"
                    :key="itemIndex"
                  >
                    <div class="cell" v-for="(_, index) in item" :key="index">
                      {{ _ }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-wrapper" v-if="stock_info[activeName2].old.length">
            <div class="title">旧料</div>
            <div class="box" v-if="stock_info[activeName2].old.length">
              <div class="table">
                <div
                  class="row"
                  :class="itemIndex === 0 ? 'bg' : ''"
                  v-for="(item, itemIndex) in stock_info[activeName2].old"
                  :key="itemIndex"
                >
                  <div class="cell" v-for="(_, index) in item" :key="index">
                    {{ _ }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { descReq } from "@/api/work/changeShifts.js";

export default {
  data() {
    return {
      descLoading: false,
      activeName: "sale_info",
      activeName2: "material",
      count_info: {
        shifts_basic: [],
        stock_count: [],
        trade_count: [],
      },
      sale_info: {
        material: {
          by_grams: [],
          by_unit: [],
          count: [],
          old: [],
        },
        stock: {
          by_grams: [],
          by_unit: [],
          count: [],
          old: [],
        },
        type: {
          by_grams: [],
          by_unit: [],
          count: [],
          old: [],
        },
      },
      stock_info: {
        material: {
          by_grams: [],
          by_unit: [],
          count: [],
          old: [],
        },
        stock: {
          by_grams: [],
          by_unit: [],
          count: [],
          old: [],
        },
        type: {
          by_grams: [],
          by_unit: [],
          count: [],
          old: [],
        },
      },
    };
  },
  props: {
    shifts_id: Number,
  },
  watch: {
    activeName2() {
      console.log(this.activeName2);
    },
  },
  created() {
    this.descLoading = true;
    descReq({
      shifts_id: this.shifts_id,
    }).then((res) => {
      if (res.code === 1) {
        this.descLoading = false;
        this.count_info = res.data.count_info;
        this.sale_info = res.data.sale_info;
        this.stock_info = res.data.stock_info;
      }
    });
  },
  methods: {
    goBack() {
      this.$emit("onBack");
    },
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  .box {
    width: 40%;
    flex-shrink: 0;
    margin-right: 10px;
  }
  .title {
    margin: 20px 0;
    font-weight: bold;
    position: relative;
    left: 10px;
    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 12px;
      background: #409eff;
      border-radius: 2px;
      left: -9px;
      top: 5px;
    }
  }
  .tag {
    background: #ccc;
    line-height: 40px;
    text-align: center;
    // margin: 10px 0;
    color: black;
    background: linear-gradient(0deg, #f3f9ff, #ffffff);
    border: 1px solid #ddd;
  }
  .table {
    border-right: none;
    border-bottom: none;
    .bg {
      background: #e8e8e8;
    }
    .row {
      display: flex;
      .cell {
        flex: 1;
        text-align: center;
        line-height: 40px;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
    }
  }
}
.wrapper {
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  .item {
    .title {
      font-weight: bold;
      margin-top: 20px;
      position: relative;
      padding-left: 10px;
      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 12px;
        background: #409eff;
        border-radius: 2px;
        left: 0px;
        top: 5px;
      }
    }
    .info {
      margin-top: 5px;
      margin-left: 20px;
      display: flex;
      flex-wrap: wrap;
      .block {
        width: 25%;
        margin-bottom: 5px;
        display: flex;
      }
    }
  }
}
</style>
