<template>
  <div class="wrapper">
    <template v-for="(item, index) in list">
      <div :key="index">
        <div class="title" v-if="!item.field">
          {{ item.title }}
          <!-- <el-checkbox v-model="checked" @change="handleCheck">全选</el-checkbox> -->
        </div>
        <Check v-if="item.children" :list.sync="item.children" />
      </div>
    </template>
    <el-checkbox-group v-model="checkList" @change="handleChange">
      <template v-for="(item, index) in list">
        <el-checkbox
          :key="index"
          :label="item.field"
          v-if="item.field"
          :checked="item.status ? true : false"
          :disabled="
            item.field === 'stock_count' || item.field === 'stock_weight'
              ? true
              : false
          "
          >{{ item.title }}</el-checkbox
        >
      </template>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "Check",
  data() {
    return {
      // checked: true,
      checkList: [],
    };
  },
  created() {
    console.log(this.checkList, 'list');
  },
  props: {
    list: Array,
  },
  methods: {
    handleCheck() {
      console.log(this.list);
    },
    handleChange() {
      this.list.forEach((item, index) => {
        let newItem = { ...item, status: 0 };
        this.checkList.forEach((check) => {
          if (check === item.field) {
            newItem = { ...item, status: 1 };
          }
        });
        this.list[index] = newItem;
      });
      // const newList = this.list.map((item) => {
      //   let newItem = { ...item, status: 0 };
      //   this.checkList.forEach((check) => {
      //     if (check === item.field) {
      //       newItem = { ...item, status: 1 };
      //     }
      //   });
      //   return newItem;
      // });
      // this.$emit('update: list', newList);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  margin-left: 10px;
  .title {
    margin-left: 10px;
    line-height: 30px;
    font-weight: bold;
  }
  /deep/ .el-checkbox {
    width: 200px;
  }
}
</style>
