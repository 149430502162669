import { post } from '@/utils/request';

const getEmployeeReq = () => post({
  url: '/store/common.select/getEmployee',
});

const getListReq = (params) => post({
  url: '/store/system.Shifts/list',
  data: {
    ...params,
  },
});

// 配置获取
const getSetReq = (params) => post({
  url: '/store/system.Shifts/set',
  data: {
    ...params,
  },
});

// 配置修改
const setupReq = (params) => post({
  url: '/store/system.Shifts/setup',
  data: {
    ...params,
  },
});

// 创建交接班
const createReq = (params) => post({
  url: '/store/system.Shifts/create',
  data: {
    ...params,
  },
});

// 接班/拒接/取消操作
const replyReq = (params) => post({
  url: '/store/system.Shifts/reply',
  data: {
    ...params,
  },
});

// 查看详情
const descReq = (params) => post({
  url: '/store/system.Shifts/desc',
  data: {
    ...params,
  },
});

export {
  descReq,
  replyReq,
  createReq,
  getEmployeeReq,
  getListReq,
  getSetReq,
  setupReq,
};
