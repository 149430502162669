<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="交接班配置">
      </el-page-header>
    </div>
    <div class="add" v-if="list.length">
      <Check :list.sync="list"  />
      <div class="save">
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getSetReq, setupReq, replyReq } from '@/api/work/changeShifts.js';
import Check from './Check.vue';

export default {
  data() {
    return {
      checkList: [],
      list: [],
    };
  },
  components: {
    Check,
  },
  created() {
    this.getSet();
  },
  watch: {
    list: {
      handler(val) {
        console.log(val);
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$emit('onBack');
    },
    save() {
      console.log(this.list);
      setupReq({
        data: JSON.stringify(this.list),
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('保存成功');
          }
        });
    },
    getSet() {
      getSetReq()
        .then((res) => {
          if (res.code === 1) {
            this.list = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.add {
  background: white;
  padding: 20px 0 100px 0;
}
.save {
  border-top: 1px solid #ddd;
  position: fixed;
  left: 0;
  right: 0;                                                   
  background: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 80px;
  align-items: center;
  z-index: 9;
}
</style>
